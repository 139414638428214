<template>
    <v-col v-for="(bank, index) in this.bank" :key="bank.id" cols="12" class="pa-1">
        <v-card elevation="3">
            <v-card-actions>
                <v-row justify="end" class="px-2 text-overline" align="center">
                    <v-col class="text-left" cols="6">
                        {{ bank.bank_tag }}
                    </v-col>
                    <v-col class="text-right" cols="6">
                        <v-btn :append-icon="bank.bank_show ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="indigo-darken-2"
                            variant="text" @click="bank.bank_show = !bank.bank_show">
                            Show
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-divider class="mx-4"></v-divider>
            <v-expand-transition>
                <div v-show="bank.bank_show">
                    <v-container class="px-3 pt-0 pb-3">
                        <div>
                            <v-data-table :headers="headers" :items="bank.sim"
                                :sort-by="[{ key: 'sim_name', order: 'asc' }]" density="compact" :items-per-page="-1"
                                class="elevation-1" :loading="load(bank)" loading-text="loading... Please wait">

                                <template v-slot:top>

                                    <v-dialog :scrim="true" v-model="dialog" hide-overlay max-width="500px">
                                        <v-card>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12" sm="6" md="6">
                                                            <v-text-field v-model="editedItem.drops" label="Drop name"
                                                                density="compact"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" md="6">
                                                            <v-text-field v-model="editedItem.fixed_simnum"
                                                                label="Phone number" density="compact"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>

                                            <template v-slot:actions>
                                                <v-btn color="indigo-darken-2" variant="text" @click="close">
                                                    Cancel
                                                </v-btn>
                                                <v-btn class="flex-grow-1" color="indigo-darken-2" variant="tonal"
                                                    @click="save(item)">
                                                    Save
                                                </v-btn>
                                            </template>

                                        </v-card>
                                    </v-dialog>

                                </template>

                                <template v-slot:[`item.sim_login`]="{ value }">
                                    <v-icon :color="value == '11' ? 'green-lighten-2' : 'red-darken-1'"
                                        icon="mdi-circle-medium"></v-icon>
                                    <!-- <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        {{ value == '11' ? 'ready' : 'empty' }}
                                    </span> -->
                                </template>

                                <template v-slot:[`item.bank_name`]>
                                    <span style="white-space:nowrap">
                                        {{ bank.bank_tag }}
                                    </span>
                                </template>

                                <template v-slot:[`item.sim_name`]="{ value }">
                                    <span>
                                        {{ value.toString().slice(-3) }}
                                    </span>
                                </template>

                                <template v-slot:[`item.drops`]="{ value }">
                                    <span>
                                        {{ value }}
                                    </span>
                                </template>

                                <template v-slot:[`item.fixed_simnum`]="{ value }">
                                    <span>
                                        {{ value }}
                                    </span>
                                </template>

                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-row no-gutters>
                                        <v-col cols="6">
                                            <v-icon size="small" class="me-2" @click="editItem(index, item)">
                                                mdi-pencil
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-icon size="small" class="me-2" @click="unlink(index, item)">
                                                mdi-window-close
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </template>

                                <template v-slot:no-data>
                                    <v-btn color="primary" @click="initialize">
                                        Reset
                                    </v-btn>
                                </template>

                                <template v-slot:bottom>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </div>
            </v-expand-transition>

        </v-card>
    </v-col>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
    name: 'SimTable',
    props: {

    },
    setup() {
        /*  const store = useStore();
         store.dispatch("bank/getAll");
         const bank = computed(() => store.state.bank.bank_list);
         return {
             bank,
         } */
    },
    data: () => ({
        bank: [],
        dialog: false,
        loading: false,
        headers: [
            { title: '', align: 'start', key: 'sim_login', sortable: true, width: '65px' },
            { title: 'Bank', key: 'bank_name', sortable: false, width: '85px' },
            { title: 'Cell', key: 'sim_name', sortable: true, width: 'auto' },
            { title: 'Drop', key: 'drops', sortable: true, width: 'auto' },
            { title: 'Number', key: 'fixed_simnum', sortable: false, width: 'auto' },
            { title: 'Action', key: 'actions', sortable: false, width: '85px' },
        ],
        editedIndex: -1,
        bankIndex: -1,
        editedItem: {
            drops: '',
            fixed_simnum: '',
        },
        defaultItem: {
            drops: '',
            fixed_simnum: '',
        },
    }),
    computed: {

    },

    watch: {
        dialog(val) {
            val || this.close()
        },

    },

    created() {
        this.initialize()
    },

    methods: {
        async initialize() {
            const store = useStore();
            this.loading = true

            this.$nextTick(() => {
                store.dispatch("bank/getAll");
                const bank = computed(() => store.state.bank.bank_list);
                this.bank = bank
                this.loading = false
            });
        },

        editItem(index, item) {
            this.bankIndex = index
            this.editedIndex = this.bank[this.bankIndex].sim.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.bankIndex = -1
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.bank[this.bankIndex].sim[this.editedIndex], this.editedItem)
                this.$store.dispatch("bank/link", { id: this.bank[this.bankIndex].sim[this.editedIndex].id, fixed_simnum: this.editedItem.fixed_simnum, drops: this.editedItem.drops });
            } else {
                //this.fake_bank.push(this.editedItem)
            }
            this.close()
        },
        unlink(index, item) {
            const empty = {
                drops: '',
                fixed_simnum: '',
            }
            this.bankIndex = index
            this.editedIndex = this.bank[this.bankIndex].sim.indexOf(item)
            Object.assign(this.bank[this.bankIndex].sim[this.editedIndex], empty)
            this.$store.dispatch("bank/unlink", { id: this.bank[this.bankIndex].sim[this.editedIndex].id });
        },

        load(val) {
            if (!val) this.loading = true;
            setTimeout(() => (this.loading = false), 1000);
        },
    },

};
</script>

<style scoped></style>